import cheerio from "cheerio"

export function stripAttributesFromTag({
  htmlString = "",
  tag,
  targetAttributes
}: {
  htmlString: string
  tag: string
  targetAttributes: string
}): string {
  const $ = cheerio.load(htmlString, null, false)

  $(tag).removeAttr(targetAttributes)

  return $.root().html() || htmlString
}

export function wrapElementInTag({
  htmlString = "",
  selector,
  wrapper
}: {
  htmlString: string
  selector: string
  wrapper: string
}): string {
  const $ = cheerio.load(htmlString, null, false)

  $(selector).wrap(wrapper)

  return $.root().html() || htmlString
}
